import React, { FC } from "react";
import { Link } from "gatsby";

type Item = {
  path: string;
  title: string;
  children: Array<object>;
};

type Props = {
  items: Item[];
};

const Navigation: FC<Props> = ({ items }) => {
  const isActive = (path: string): boolean => {
    if (typeof window === "undefined") {
      return false;
    }

    return location?.pathname === path;
  };

  return (
    <ul>
      {items.map((item: any, index: number) => (
        <li className={isActive(item.path) ? "font-bold" : ""} key={index}>
          <Link to={item.path}>{item.title}</Link>
          {item.children.length > 0 && <Navigation items={item.children} />}
        </li>
      ))}
    </ul>
  );
};

export default Navigation;
