import React, { Fragment, FC, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import { fallDown as Menu } from "react-burger-menu";
import Burger from "@animated-burgers/burger-slip";
import "@animated-burgers/burger-slip/dist/styles.css";

import "./../styles/app.scss";
import Navigation from "./../components/Navigation";
import { flatListToHierarchical } from "../utils/helpers";
import LanguageSelector from "../components/LanguageSelector";
import LogoLink from "../components/LogoLink";

type LayoutProps = {
  children: any;
  data: {
    wpPage: { uri: string; translations: object[]; language: { slug: string } };
    allWpMenuItem: { nodes: Array<object> };
  };
};

const PageLayout: FC<LayoutProps> = ({ children, data }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
    query NavigationMenu {
      wp {
        seo {
          contentTypes {
            post {
              title
              schemaType
              metaRobotsNoindex
              metaDesc
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
              cardType
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);
  const menuItems = flatListToHierarchical(data?.allWpMenuItem?.nodes);
  const translations = data?.wpPage?.translations;
  const uri = data?.wpPage?.uri;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <SEOContext.Provider value={{ global: seo }}>
      <Fragment>
        <div className="relative" id="outer-container">
          <header className="fixed top-0 left-0 w-full bg-white shadow-navigation z-[1101]">
            <div className="container px-5 md:px-20 2xl:px-4 flex justify-between gap-x-4 items-center h-[90px]">
              <LogoLink uri={uri} />
              <nav>
                <Navigation items={menuItems} />
                <LanguageSelector translations={translations} isResponsive />
                <Burger
                  className="block md:hidden"
                  isOpen={isOpen}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </nav>
            </div>
          </header>

          <Menu isOpen={isOpen} width="100%" fallDown>
            <div className="text-center pt-16">
              <LanguageSelector translations={translations} />
            </div>
          </Menu>

          <main>{children}</main>
          <footer className="bg-[#0020370D]">
            <div className="container grid grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-8 md:gap-4 min-h-[105px] text-[#001C3899] px-5 md:px-20 2xl:px-4 py-4 text-xs">
              <ul className="text-right md:text-left order-2 md:order-1">
                {/* <li className="hover:opacity-80 underline pb-2">
                  <Link to="/">Privātuma politika</Link>
                </li>
                <li className="hover:opacity-80 underline pb-2">
                  <Link to="/">Paziņojums par sīkdatņu izmantošanu</Link>
                </li>
                <li className="hover:opacity-80 underline">
                  <Link to="/">Noteikumi un nosacījumi</Link>
                </li> */}
              </ul>
              <div className="order-1 md:order-2 md:m-auto">
                <LogoLink uri={uri} />
              </div>
              <div className="col-span-2 md:col-span-1 order-3 text-center md:text-right my-auto">
                © {new Date().getFullYear()} SUPE Law ZAB SIA. <br />
                Visas tiesības aizsargātas.
              </div>
            </div>
          </footer>
        </div>
      </Fragment>
    </SEOContext.Provider>
  );
};

export default PageLayout;
