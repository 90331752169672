export const flatListToHierarchical = (
  data: any[] = [],
  {
    idKey = "key",
    parentKey = "parentId",
    childrenKey = "children",
  }: {
    idKey?: string;
    parentKey?: string;
    childrenKey?: string;
  } = {}
) => {
  const tree: any[] = [];
  const childrenOf: { [key: string]: any[] } = {};

  data.forEach((item) => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;

    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];

    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });

  return tree;
};
