import React, { FC } from "react";
import { Link } from "gatsby";
import Logo from "../assets/logo.svg";

type LinkProps = {
  uri: string;
};

const LogoLink: FC<LinkProps> = ({ uri }) => {
  return (
    <Link to={uri}>
      <Logo />
    </Link>
  );
};

export default LogoLink;
