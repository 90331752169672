import React, { FC } from "react";
import { Link } from "gatsby";

type Props = {
  translations: Array<object>;
  isResponsive?: boolean;
};

type ItemProps = {
  path: string;
  language: string;
};

const Item: FC<ItemProps> = ({ path, language }) => {
  return (
    <li className="text-sm uppercase text-[#001C3899]">
      <Link to={path}>{language}</Link>
    </li>
  );
};

const LanguageSelector: FC<Props> = ({
  translations,
  isResponsive = false,
}) => {
  return (
    <ul className={isResponsive ? "hidden md:block" : ""}>
      {translations?.map((element, index) => {
        return (
          <Item
            key={index}
            language={element?.language?.slug}
            path={element?.uri}
          />
        );
      })}
    </ul>
  );
};

export default LanguageSelector;
